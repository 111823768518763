import { Container, Highlight, Text } from './DataItem.styles'

interface Props {
  highlight: string
  text: string
}

export const DataItem = ({ highlight, text }: Props) => {
  return (
    <Container>
      <Highlight>{highlight}</Highlight>
      <Text>{text}</Text>
    </Container>
  )
}
