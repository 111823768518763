import { Container, Content, Icon, Title } from './AdvantageItem.styles'
import { ReactElement } from 'react'

interface Props {
  icon: ReactElement
  title: string
  content: string
  colorful?: boolean
}

export const AdvantageItem = ({ icon, title, content, colorful }: Props) => {
  return (
    <Container colorful={colorful}>
      <Icon>{icon}</Icon>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Container>
  )
}
