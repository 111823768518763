import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  colors: {
    primary: '#4F8DFF', // Cor Primária
    secondary: '#BDBDBD', // Cor Secundária
    tertiary: '#757B8A', //  Cor Terciária
    quaternary: '#D6D6D6', // Cor Quaternária
    quinary: '#F2F4FC', // Cor Quintenária
    sixth: '#EB6E46',
    highlight: '#F5B437', // Cor de Destaque
    black: '#000000', // Preto
    white: '#FFFFFF', // Branco
  },
  fonts: {
    primary: 'Poppins, sans-serif',
    secondary: 'Inter, sans-serif',
    tertiary: 'Poppins, sans-serif',
  },
}
