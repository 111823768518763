import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 6rem;
  border-top: 1px solid ${(props) => props.theme.colors.quaternary};
  padding: 1.75rem 0;

  ${screens.mobile`
      flex-direction: column;
      gap: 1rem
    `}
`

export const Title = styled.h1`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.black};
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
`

export const Description = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.black};
  line-height: 1.75rem;
`
