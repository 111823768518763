import { LeadModel } from '../../../types/leadModel'
import {
  Actions,
  Container,
  Content,
  Copyright,
  Info,
  InfoIcon,
  InfoLink,
  Infos,
  Link,
  Links,
  LogoImage,
  Terms,
  Title,
} from './Footer.styles'

import Logo from '@/../../src/assets/images/logo.png'
import { RiMailLine } from 'react-icons/ri'
import useTimeSpentInSection from '../../../hooks/useTimeSpentInSection'
import { useEffect } from 'react'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const Footer = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('footer')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead('time-spent', 'footer', 'footer-container', timeSpent / 1000)
  }, [timeSpent])

  return (
    <Container id="footer">
      <Content>
        <Title>O parceiro contábil que cuida de tudo para você</Title>
        <Infos>
          {/* <Info>
            <InfoIcon>
              <RiMapPin2Line />
            </InfoIcon>
            <InfoText>1929, Pinheiros, São Paulo - SP, Brasil</InfoText>
          </Info> */}
          <Info>
            <InfoIcon>
              <RiMailLine />
            </InfoIcon>
            <InfoLink href="mailto:contato_contabilidade@brbw.com.br">
              contato_contabilidade@brbw.com.br
            </InfoLink>
          </Info>
        </Infos>
      </Content>
      <Actions>
        <LogoImage src={Logo} />
        <Links>
          <Link
            href="#section-three"
            onClick={() => {
              HandleLead('click', 'footer', 'link-section-three')
            }}
            onMouseOver={() =>
              HandleLead('mouse-over', 'footer', 'link-section-three')
            }
          >
            Benefícios
          </Link>
          <Link
            href="#section-four"
            onClick={() => HandleLead('click', 'footer', 'link-section-four')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'footer', 'link-section-four')
            }
          >
            Sobre Nós
          </Link>
          <Link
            href="#section-five"
            onClick={() => HandleLead('click', 'footer', 'link-section-five')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'footer', 'link-section-five')
            }
          >
            Serviços
          </Link>
          <Link
            href="#section-six"
            onClick={() => HandleLead('click', 'footer', 'link-section-six')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'footer', 'link-section-six')
            }
          >
            Equipe
          </Link>
          <Link
            href="#section-eight"
            onClick={() => HandleLead('click', 'footer', 'link-section-eight')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'footer', 'link-section-eight')
            }
          >
            FAQ
          </Link>
        </Links>
        {/* <Socials>
          <Social>
            <FaFacebook href="" />
          </Social>
          <Social>
            <FaTwitter href="" />
          </Social>
          <Social>
            <FaInstagram href="" />
          </Social>
          <Social>
            <FaLinkedin href="" />
          </Social>
        </Socials> */}
      </Actions>
      <Terms>
        <Copyright>© Todos os seus direitos reservados</Copyright>{' '}
        {/* <PolicyPrivacy>
          <PolicyPrivacyLink href="">Termos e Condições</PolicyPrivacyLink>{' '}
          <PolicyPrivacyLink href="">Política de Privacidade</PolicyPrivacyLink>
        </PolicyPrivacy> */}
      </Terms>
    </Container>
  )
}
