import { useEffect } from 'react'
import {
  Container,
  Content,
  Title,
  Text,
  Data,
  Image,
} from './SectionFour.styles'
import { LeadModel } from '../../../../../types/leadModel'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { DataItem } from '../../../../commons/DataItem'
import ImageSection from '@/../../src/assets/images/section-four-image.png'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionFour = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-four')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-four',
        'section-four-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-four">
      <Title>
        Somos mais que um escritório contábil – somos parceiros na jornada do
        seu negócio.
      </Title>
      <Content>
        <Text>
          Há mais de 8 anos no mercado, nossa empresa tem se destacado pela
          dedicação e paixão em fornecer serviços contábeis especializados para
          empresas do Simples Nacional.
        </Text>
        <Text>
          Nossa <b>missão</b> é simplificar a gestão contábil para que nossos
          clientes possam focar no que realmente importa: o crescimento e
          sucesso de suas empresas.
        </Text>
        <Text>
          Nosso <b>compromisso</b> é com o seu sucesso. Combinando expertise
          contábil e atendimento personalizado, estamos aqui para garantir que
          sua empresa prospere e se mantenha sólida em um mercado competitivo.
        </Text>
      </Content>
      <Data>
        <DataItem highlight="250+" text="Serviços atendidos" />
        <DataItem highlight="1100+" text="Consultorias e Dúvidas realizadas" />
      </Data>
      <Image src={ImageSection} />
    </Container>
  )
}
