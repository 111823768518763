import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding: 7rem 0 3.25rem;
  background: ${(props) => props.theme.colors.white};
  display: grid;
  grid-template-columns:
    minmax(7.5rem, 1fr) minmax(auto, 2fr) minmax(auto, 10fr)
    minmax(7.5rem, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    '..... title title   .....'
    '..... ..... content .....';

  ${screens.laptop`
     grid-template-columns:
    minmax(2.5rem, 1fr) minmax(auto, 2fr) minmax(auto, 10fr)
    minmax(2.5rem, 1fr);
    `}

  ${screens.tablet`
      grid-template-columns:
      minmax(1.25rem, 1fr) minmax(auto, 10fr)
      minmax(1.25rem, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
        '..... title   .....'
        '..... content .....';
    `}

  ${screens.mobile`
      grid-template-columns:
      minmax(1.25rem, 1fr) minmax(auto, 10fr)
      minmax(1.25rem, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
        '..... title   .....'
        '..... content .....';
    `}
`

export const Title = styled.h1`
  grid-area: title;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3rem;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 6rem;
  max-width: 47.275rem;

  ${screens.laptop`
     font-size: 2.5rem;
    `}

  ${screens.tablet`
      font-size: 2rem;
      margin-bottom: 3.5rem;
    `}

  ${screens.mobile`
      font-size: 2rem;
      margin-bottom: 3.5rem;
    `}
`

export const Content = styled.div`
  grid-area: content;
  width: 100%;
`
