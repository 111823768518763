import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Highlight = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
`

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.tertiary};
  font-weight: 500;
`
