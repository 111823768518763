import styled from 'styled-components'

export const Button = styled.button`
  all: unset;
  width: 12.125rem;
  height: 3.5rem;
  border-radius: 9999px;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
  cursor: pointer;
  text-align: center;
`
