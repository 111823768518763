import { ReactElement } from 'react'
import { Container, Content, Description, Title } from './ServiceItem.styles'

interface Props {
  title: string
  description: string
  action?: ReactElement
  onClick: () => void
  onMouseOver: () => void
}

export const ItemService = ({
  title,
  description,
  action,
  onClick,
  onMouseOver,
}: Props) => {
  return (
    <Container onClick={onClick} onMouseOver={onMouseOver}>
      <Title>{title}</Title>
      <Content>
        <Description>{description}</Description>
        {!!action && action}
      </Content>
    </Container>
  )
}
