import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding-top: 7rem;
  background: ${(props) => props.theme.colors.secondary};
  display: grid;
  grid-template-columns: minmax(7.5rem, 1fr) minmax(auto, 12fr) minmax(
      7.5rem,
      1fr
    );
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    '..... content .....'
    '..... actions  .....'
    '..... terms   .....';

  ${screens.laptop`
    padding-top: 5rem;
      grid-template-columns: minmax(2.5rem, 1fr) minmax(auto, 12fr) minmax(
      2.5rem,
      1fr
    );
    `}

  ${screens.tablet`
  padding-top: 5rem;
       grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 10fr) minmax(
      1.5rem,
      1fr
    );
    `}

  ${screens.mobile`
  padding-top: 5rem;
       grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 10fr) minmax(
      1.5rem,
      1fr
    );
    `}
`

export const Content = styled.div`
  grid-area: content;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 3.25rem;

  ${screens.tablet`
       flex-direction: column
       gap: 2rem;
    `}

  ${screens.mobile`
       flex-direction: column
    `}
`

export const Title = styled.h1`
  font-size: 3.5rem;
  font-family: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
  max-width: 41.875rem;

  ${screens.laptop`
        max-width: 30rem;
        font-size: 2.5rem;
    `}

  ${screens.tablet`
        max-width: 20rem;
        font-size: 1.5rem;
    `}

  ${screens.mobile`
       font-size: 2rem;
    `}
`

export const Infos = styled.div`
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const Info = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`

export const InfoIcon = styled.div`
  min-width: 2.875rem;
  min-height: 2.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  font-size: 1.5rem;

  ${screens.tablet`
        min-width: 2.5rem;
        min-height: 2.5rem;
    `}

  ${screens.mobile`
        min-width: 2.875rem;
        min-height: 2.875rem;
    `}
`

export const InfoText = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: ${(props) => props.theme.fonts.secondary};

  ${screens.tablet`
       font-size: .875rem
    `}

  ${screens.mobile`
       font-size: 1rem
    `}
`

export const InfoLink = styled.a`
  all: unset;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: ${(props) => props.theme.fonts.secondary};
  cursor: pointer;

  ${screens.tablet`
       font-size: .875rem
    `}

  ${screens.mobile`
       font-size: 1rem
    `}
`

export const Actions = styled.div`
  grid-area: actions;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5rem 0 2.5rem;

  ${screens.tablet`
        margin: 2rem 0 1.5rem;
       
    `}

  ${screens.mobile`
       margin: 1rem 0 2.5rem;
       flex-direction: column;
       align-items: flex-start;
       gap: 2.25rem
    `}
`

export const LogoImage = styled.img`
  grid-area: logo;
  width: 10rem;
  height: auto;

  ${screens.tablet`
      width: 7rem;
    `}

  ${screens.mobile`
      width: 10rem;
      margin-left: -.5rem
    `}
`

export const Links = styled.div`
  grid-area: links;
  display: flex;
  gap: 1rem 2rem;
  justify-content: center;
  transition: all 0.1s ease-in-out;

  ${screens.tablet`
       gap: 1rem;
    `}

  ${screens.mobile`
      justify-content: flex-start;
      gap: 1rem 2rem;
      flex-wrap: wrap
    `}
`

export const Link = styled.a`
  all: unset;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.black};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;

  ${screens.tablet`
       font-size: .875rem
    `}

  ${screens.mobile`
       font-size: 1rem
    `}
`

export const Socials = styled.div`
  display: flex;
  gap: 1.5rem;

  ${screens.tablet`
        gap: 1rem;
    `}

  ${screens.mobile`
       gap: 1.5rem;
    `}
`

export const Social = styled.a`
  all: unset;
  font-size: 1.25rem;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;

  ${screens.tablet`
        font-size: 1rem;
    `}

  ${screens.mobile`
       font-size: 1.5rem;
    `}
`

export const Terms = styled.div`
  grid-area: terms;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2.5rem 0;
  border-top: 2px solid ${(props) => props.theme.colors.black};

  ${screens.mobile`
       align-items: flex-start;
       flex-direction: column-reverse;
       gap: 2rem;
    `}
`

export const Copyright = styled.p`
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.black};

  ${screens.tablet`
        font-size: .875rem;
    `}

  ${screens.mobile`
       font-size: 1rem;
    `}
`

export const PolicyPrivacy = styled.div`
  display: flex;
  gap: 2.875rem;

  ${screens.mobile`
        gap: 1rem;
        flex-direction: column;
    `}
`

export const PolicyPrivacyLink = styled.a`
  all: unset;
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.black};

  ${screens.tablet`
        font-size: .875rem;
    `}

  ${screens.mobile`
       font-size: 1rem;
    `}
`
