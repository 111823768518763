import styled from 'styled-components'

export const Container = styled.div`
  max-width: 17.25rem;
  min-width: 17.25rem;
  height: auto;
`

export const AvatarIcon = styled.div`
  width: 100%;
  height: 18.375rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: ${(props) => props.theme.colors.secondary};

  & > :first-child {
    font-size: 14rem;
    color: ${(props) => props.theme.colors.primary};
  }
`

export const ProfileImage = styled.img`
  width: 100%;
  height: 18.375rem;
  border-radius: 1rem;
  object-fit: cover;
  object-position: center;
  background: #000;
`

export const Name = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.25rem;
  color: ${(props) => props.theme.colors.black};
  margin: 1rem 0 0.5rem;
`

export const Function = styled.p`
  max-width: 90%;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.tertiary};
`
