import { useState } from 'react'
import {
  Action,
  Container,
  Content,
  Icon,
  Link,
  Links,
  LogoImage,
  MobileActions,
  MobileOptions,
} from './Header.styles'

import Logo from '../../../assets/images/logo.png'
import { MainButton } from '../MainButton'
import { IoMenu, IoCloseOutline } from 'react-icons/io5'
import { LeadModel } from '../../../types/leadModel'
import { HeaderButton } from '../HeaderButton'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const Header = ({ SendLead, ip, userAgent }: Props) => {
  const [active, setActive] = useState(false)

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
    }

    const response = await SendLead(lead)

    return response
  }

  return (
    <Container id="header">
      <Content active={active}>
        <LogoImage
          src={Logo}
          alt="logo"
          onClick={() => HandleLead('click', 'header', 'logo')}
          onMouseOver={() => HandleLead('mouse-over', 'header', 'logo')}
        />
        <Links>
          <Link
            href="#section-three"
            onClick={() => {
              HandleLead('click', 'header', 'link-section-three')
            }}
            onMouseOver={() =>
              HandleLead('mouse-over', 'header', 'link-section-three')
            }
          >
            Benefícios
          </Link>
          <Link
            href="#section-four"
            onClick={() => HandleLead('click', 'header', 'link-section-four')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'header', 'link-section-four')
            }
          >
            Sobre Nós
          </Link>
          <Link
            href="#section-five"
            onClick={() => HandleLead('click', 'header', 'link-section-five')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'header', 'link-section-five')
            }
          >
            Serviços
          </Link>
          <Link
            href="#section-six"
            onClick={() => HandleLead('click', 'header', 'link-section-six')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'header', 'link-section-six')
            }
          >
            Equipe
          </Link>
          <Link
            href="#section-eight"
            onClick={() => HandleLead('click', 'header', 'link-section-eight')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'header', 'link-section-eight')
            }
          >
            FAQ
          </Link>
        </Links>
        <Action>
          <HeaderButton title="Contato" action={() => {}} />
        </Action>
        <Icon>
          {active ? (
            <IoCloseOutline onClick={() => setActive(false)} />
          ) : (
            <IoMenu onClick={() => setActive(true)} />
          )}
        </Icon>
        <MobileOptions active={active}>
          {' '}
          <MobileActions>
            <Link
              href="#section-three"
              onClick={() => {
                HandleLead('click', 'header', 'link-section-three')
                setActive(false)
              }}
              onMouseOver={() =>
                HandleLead('mouse-over', 'header', 'link-section-three')
              }
            >
              Benefícios
            </Link>
            <Link
              href="#section-four"
              onClick={() => {
                HandleLead('click', 'header', 'link-section-four')
                setActive(false)
              }}
              onMouseOver={() =>
                HandleLead('mouse-over', 'header', 'link-section-four')
              }
            >
              Sobre Nós
            </Link>
            <Link
              href="#section-five"
              onClick={() => {
                HandleLead('click', 'header', 'link-section-five')
                setActive(false)
              }}
              onMouseOver={() =>
                HandleLead('mouse-over', 'header', 'link-section-five')
              }
            >
              Serviços
            </Link>
            <Link
              href="#section-six"
              onClick={() => {
                HandleLead('click', 'header', 'link-section-six')
                setActive(false)
              }}
              onMouseOver={() =>
                HandleLead('mouse-over', 'header', 'link-section-six')
              }
            >
              Equipe
            </Link>
            <Link
              href="#section-eight"
              onClick={() => {
                HandleLead('click', 'header', 'link-section-eight')
                setActive(false)
              }}
              onMouseOver={() =>
                HandleLead('mouse-over', 'header', 'link-section-eight')
              }
            >
              FAQ
            </Link>
            <MainButton
              title="Contato"
              onClick={() => {
                HandleLead('click', 'header', 'main-button')
              }}
              onMouseOver={() => {
                HandleLead('mouse-over', 'header', 'main-button')
              }}
            />
          </MobileActions>
        </MobileOptions>
      </Content>
    </Container>
  )
}
