import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  display: grid;
  padding: 5rem 0;
  background: ${(props) => props.theme.colors.secondary};
  grid-template-columns: minmax(7.5rem, 1fr) minmax(auto, 6fr) minmax(auto, 6fr) minmax(
      7.5rem,
      1fr
    );
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    '..... image title   .....'
    '..... image content .....'
    '..... image data    .....';

  ${screens.laptop`
        grid-template-columns: minmax(2.5rem, 1fr) minmax(auto, 6fr) minmax(auto, 6fr) minmax(
            2.5rem,
            1fr
          );
    `}

  ${screens.tablet`
      grid-template-columns: minmax(1.25rem, 1fr) minmax(auto, 10fr) minmax(
      1.25rem,
      1fr
    );
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      '..... image   .....'
      '..... title   .....'
      '..... content .....'
      '..... data    .....';
    `}

  ${screens.mobile`
      grid-template-columns: minmax(1.25rem, 1fr) minmax(auto, 10fr) minmax(
      1.25rem,
      1fr
    );
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      '..... image   .....'
      '..... title   .....'
      '..... content .....'
      '..... data    .....';
    `}
`

export const Title = styled.h1`
  grid-area: title;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3rem;
  color: ${(props) => props.theme.colors.black};
  margin: 2.875rem 0 2rem;

  ${screens.laptop`
      font-size: 2.5rem;
    `}

  ${screens.tablet`
      font-size: 2rem;
    `}

  ${screens.mobile`
      font-size: 2rem;
    `}
`

export const Content = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1.125rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.tertiary};

  ${screens.laptop`
       font-size: 1rem;
    `}
`

export const Data = styled.div`
  grid-area: data;
  display: flex;
  gap: 2.875rem;
  margin-top: 3.5rem;
`

export const Image = styled.img`
  grid-area: image;
  width: 28rem;
  height: auto;

  ${screens.laptop`
      width: 22rem;
      justify-self: center;
    `}

  ${screens.tablet`
      width: 20rem;
      justify-self: center;
    `}

  ${screens.mobile`
      width: 100%;
    `}
`
