import {
  Carousel,
  CarouselItem,
  CarouselWrapper,
  Container,
  Image,
  Title,
} from './SectionTwo.styles'
import { LeadModel } from '../../../../../types/leadModel'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { useEffect } from 'react'

import LogoReceitaFederal from '@/../../src/assets/images/logo-receita-federeal.webp'
import LogoSimplesNacional from '@/../../src/assets/images/logo-simples-nacional.png'
import LogoESocial from '@/../../src/assets/images/logo-esocial.png'
import LogoSefaz from '@/../../src/assets/images/logo-sefaz.png'
import LogoSpedFiscal from '@/../../src/assets/images/logo-sped-fiscal.png'
import LogoMei from '@/../../src/assets/images/logo-mei.png'
import LogoNfe from '@/../../src/assets/images/logo-nfe.png'
import LogoIcp from '@/../../src/assets/images/logo-icp.png'
import LogoCaixa from '@/../../src/assets/images/logo-caixa.png'
import LogoSebrae from '@/../../src/assets/images/logo-sebrae.png'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionTwo = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-two')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-two',
        'section-two-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-two">
      <Title>Confiado por +200 empresas</Title>
      <CarouselWrapper>
        <Carousel>
          <CarouselItem>
            <Image src={LogoReceitaFederal} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSimplesNacional} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoESocial} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSefaz} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSpedFiscal} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoMei} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoNfe} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoIcp} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoCaixa} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSebrae} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoReceitaFederal} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSimplesNacional} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoESocial} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSefaz} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSpedFiscal} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoMei} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoNfe} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoIcp} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoCaixa} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSebrae} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoReceitaFederal} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSimplesNacional} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoESocial} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSefaz} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSpedFiscal} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoMei} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoNfe} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoIcp} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoCaixa} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSebrae} />
          </CarouselItem>
        </Carousel>
      </CarouselWrapper>
    </Container>
  )
}
