import { Button } from './HeaderButton.styles'

interface Props {
  title: string
  action: () => void
}

export const HeaderButton = ({ title, action }: Props) => {
  return <Button onClick={action}>{title}</Button>
}
