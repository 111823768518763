import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  overflow: hidden;
  display: grid;
  background: ${(props) => props.theme.colors.black};
  grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 12fr) minmax(
      1.5rem,
      1fr
    );
  grid-template-rows: auto;
  grid-template-areas: '..... content .....';

  ${screens.laptop`
      grid-template-columns: minmax(2.5rem, 1fr) minmax(auto, 5fr) minmax(auto, 8fr);
    `}

  ${screens.tablet`
      grid-template-columns:
      minmax(1.25rem, 1fr) minmax(auto, 10fr) minmax(1.25rem, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
            '..... content .....'
            '..... image .....'
    `}

  ${screens.mobile`
      grid-template-columns:
      minmax(1.25rem, 1fr) minmax(auto, 10fr) minmax(1.25rem, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
            '..... content .....'
            '..... image .....'
    `}
`

export const Content = styled.div`
  grid-area: content;
  padding: 12rem 0 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${screens.tablet`
      padding: 7.5rem 0 2rem;
      gap: 1.5rem;
    `}

  ${screens.mobile`
      padding: 7.5rem 0 2rem;
      gap: 1.5rem;
    `}
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 5rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};
  line-height: 6.25rem;
  margin-bottom: 1.5rem;

  & > span {
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 5rem;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
    line-height: 6.25rem;
  }

  ${screens.laptop`
      font-size: 2.5rem;
      font-weight: bold;
    `}

  ${screens.tablet`
      font-size: 2.5rem;
      max-width: 100%;
    `}

  ${screens.mobile`
      font-size: 2.25rem;
    `}
`

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.125rem;
  max-width: 28rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.secondary};
  line-height: 1.5rem;
  margin-bottom: 2.75rem;

  ${screens.tablet`
      max-width: 100%;
    `}

  ${screens.mobile`
     max-width: 20rem;
    `}
`

export const Action = styled.div`
  display: flex;
  gap: 2rem;
`

export const Image = styled.img`
  grid-area: image;
  width: 100%;
  max-width: 60rem;
  height: auto;

  ${screens.tablet`
       width: 135%;
       margin-left: 10vw;
       justify-self: center;
    `}

  ${screens.mobile`
       width: 135%;
       margin-left: 10vw;
       justify-self: center;
    `}
`
