import * as LeadServices from '../services/LeadService'
import { LeadModel } from '../types/leadModel'

export const useLead = (page_url: string, latency_ms: number) => {
  const SendLeadService = async (lead: LeadModel) => {
    const time_stamp = new Date()

    try {
      const response = await LeadServices.SendLeadService({
        ...lead,
        site: 'teste',
        page_url,
        latency_ms,
        time_stamp: time_stamp.toString(),
      })
      return response
    } catch (error) {
      console.error('Erro para lead:', error)
      return
    }
  }

  return {
    SendLeadService,
  }
}
