import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding: 4.375rem 2.75rem;

  ${screens.mobile`
      padding: 4.375rem 1.25rem;
    `}
`

export const Content = styled.div`
  width: 100%;
  height: 33.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 2rem;
  background: ${(props) => props.theme.colors.primary};

  ${screens.tablet`
      height: auto;
      padding: 2.25rem 2.5rem;
      border-radius: 1rem;
    `}

  ${screens.mobile`
      height: auto;
      text-align: left;
      padding: 2.25rem 2.5rem;
       border-radius: 1rem;

      button {
      width:100%}
    `}
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 4.125rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 1.5rem;
  max-width: 52rem;

  ${screens.tablet`
       font-size: 2rem;
       margin-bottom: 1rem;
       max-width: 20rem;
       line-height: 2.5rem;
    `}

  ${screens.mobile`
       font-size: 2rem;
       margin-bottom: 1rem;
       max-width: 20rem;
       line-height: 2.5rem;
    `}
`

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 3.5rem;
  max-width: 52rem;

  ${screens.tablet`
       font-size: 1rem;
       margin-bottom: 2.5rem;
    `}

  ${screens.mobile`
       font-size: 1rem;
       margin-bottom: 2.5rem;
       max-width: 20rem;
    `}
`
