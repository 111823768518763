import { useEffect } from 'react'
import { LeadModel } from '../../../../../types/leadModel'
import { Container, QuestionsContainer, Title } from './SectionEight.styles'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { CommonQuestion } from '../../../../commons/CommonQuestion'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionEight = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-eight')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-eight',
        'section-eight-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-eight">
      <Title>Tire sua Dúvida</Title>
      <QuestionsContainer>
        <CommonQuestion
          question="1. Quais são os requisitos para abrir uma empresa no Simples Nacional?"
          answer={
            <>
              <p>
                Para abrir uma empresa no Simples Nacional, é necessário atender
                aos seguintes requisitos:
              </p>
              <ul>
                <li>
                  Atividade Permitida: Verificar se a atividade econômica da
                  empresa é permitida no Simples Nacional.
                </li>
                <li>
                  Faturamento Anual: O limite de faturamento para empresas
                  optantes pelo Simples Nacional é de até R$ 4,8 milhões por
                  ano.
                </li>
                <li>
                  Documentação Necessária: São exigidos documentos pessoais (RG,
                  CPF, comprovante de residência) dos sócios, contrato social,
                  endereço comercial e informações sobre o capital social da
                  empresa.
                </li>
                <li>
                  Registro na Junta Comercial: É necessário registrar a empresa
                  na Junta Comercial do estado onde a empresa será sediada.
                </li>
              </ul>
              <p>
                Nossa equipe cuida de todo o processo, desde a análise inicial
                até a formalização da empresa, garantindo que você inicie suas
                atividades no regime tributário correto e com total
                tranquilidade.
              </p>
            </>
          }
          onClick={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-one')
          }
          onMouseOver={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-one')
          }
        />
        <CommonQuestion
          question="2. Em quanto tempo minha empresa estará oficialmente aberta?"
          answer={
            <p>
              O prazo para a abertura de uma empresa depende de diversos
              fatores, como o tipo de empresa e o estado onde será registrada.
              Geralmente, conseguimos finalizar todo o processo em até 30 dias
              úteis.
            </p>
          }
          onClick={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-two')
          }
          onMouseOver={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-two')
          }
        />
        <CommonQuestion
          question="3. Como funciona o serviço de migração de MEI para Simples Nacional?"
          answer={
            <p>
              Realizamos todo o processo de migração, desde o planejamento
              inicial até o envio da documentação necessária. Durante a
              migração, analisamos se essa é a melhor opção para o seu negócio e
              explicamos todas as implicações tributárias.
            </p>
          }
          onClick={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-three')
          }
          onMouseOver={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-three')
          }
        />
        <CommonQuestion
          question="4. Qual é o prazo para regularizar os débitos do meu CNPJ?"
          answer={
            <p>
              O prazo para regularizar os débitos do seu CNPJ pode variar
              dependendo da complexidade da situação e do número de pendências.
              Em casos simples, como dívidas recentes, a regularização pode ser
              feita em até 7 dias úteis. Para situações mais complexas, como
              débitos antigos ou com parcelamentos envolvidos, o prazo pode se
              estender para 30 a 60 dias úteis, especialmente quando há
              negociações com a Receita Federal.
              <br />
              <br />
              Nossa equipe realiza uma análise detalhada de cada caso para
              fornecer uma estimativa precisa e trabalhar com a maior agilidade
              possível.
            </p>
          }
          onClick={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-four')
          }
          onMouseOver={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-four')
          }
        />
        <CommonQuestion
          question="5. Vocês oferecem parcelamento no pagamento dos serviços?"
          answer={
            <p>
              Sim, oferecemos opções de parcelamento para serviços maiores, como
              abertura de empresas ou regularização de dívidas. Entre em contato
              para conhecer as condições.
            </p>
          }
          onClick={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-five')
          }
          onMouseOver={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-five')
          }
        />
        <CommonQuestion
          question="6. Preciso pagar mensalidade para contabilidade de uma empresa no Simples Nacional?"
          answer={
            <p>
              Sim, a contabilidade de empresas no Simples Nacional requer
              acompanhamento mensal para garantir que todas as obrigações
              fiscais sejam cumpridas. Nossos planos incluem o envio de
              declarações, emissão de guias de impostos e acompanhamento
              contábil completo.
            </p>
          }
          onClick={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-six')
          }
          onMouseOver={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-six')
          }
        />
        <CommonQuestion
          question="7. Como posso saber se minha empresa está com a Certidão Negativa de Débito (CND) em dia?"
          answer={
            <p>
              Enviamos mensalmente a Certidão Negativa de Débito para nossos
              clientes como parte do nosso serviço de contabilidade. Dessa
              forma, garantimos que sua empresa esteja sempre regularizada com a
              Receita Federal.
            </p>
          }
          onClick={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-seven')
          }
          onMouseOver={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-seven')
          }
        />
        <CommonQuestion
          question="8. Vocês atendem clientes de todo o Brasil?"
          answer={
            <p>
              Sim, nossos serviços são oferecidos para empresas em todo o
              território nacional. Realizamos reuniões online e disponibilizamos
              nossos atendimentos por e-mail, telefone e WhatsApp.
            </p>
          }
          onClick={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-eight')
          }
          onMouseOver={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-eight')
          }
        />
        <CommonQuestion
          question="9. Como é o suporte após a contratação dos serviços?"
          answer={
            <p>
              Oferecemos suporte contínuo para todos os nossos clientes. Estamos
              disponíveis para esclarecer dúvidas e resolver questões contábeis
              a qualquer momento. Além disso, realizamos revisões periódicas
              para garantir que tudo esteja em conformidade com as normas
              fiscais.
            </p>
          }
          onClick={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-nine')
          }
          onMouseOver={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-nine')
          }
        />
        <CommonQuestion
          question="10. Vocês cuidam da emissão de notas fiscais?"
          answer={
            <p>
              Sim, oferecemos serviço de emissão de notas fiscais para empresas
              do Simples Nacional. Garantimos que todas as notas sejam emitidas
              corretamente e dentro do prazo, evitando problemas futuros com o
              fisco.
            </p>
          }
          onClick={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-ten')
          }
          onMouseOver={async () =>
            HandleLead('click', 'section-eight', 'section-eight-question-ten')
          }
        />
        <CommonQuestion
          question="11. Qual é o diferencial de vocês em relação a outros escritórios de contabilidade?"
          answer={
            <p>
              Nosso principal diferencial é a proatividade. Além de cuidar das
              obrigações contábeis básicas, monitoramos e enviamos mensalmente a
              Certidão Negativa de Débito (CND), garantindo que sua empresa
              esteja sempre em dia com a Receita Federal. Isso traz segurança e
              tranquilidade para nossos clientes.
            </p>
          }
          onClick={async () =>
            HandleLead(
              'click',
              'section-eight',
              'section-eight-question-eleven'
            )
          }
          onMouseOver={async () =>
            HandleLead(
              'click',
              'section-eight',
              'section-eight-question-eleven'
            )
          }
        />
        <CommonQuestion
          question="12. Qual é o processo para parcelar dívidas com a Receita?"
          answer={
            <p>
              Analisamos sua situação fiscal e negociamos diretamente com a
              Receita Federal as melhores condições de parcelamento. Nosso
              objetivo é garantir que o parcelamento seja sustentável para o seu
              negócio, evitando bloqueios e multas.
            </p>
          }
          onClick={async () =>
            HandleLead(
              'click',
              'section-eight',
              'section-eight-question-twelve'
            )
          }
          onMouseOver={async () =>
            HandleLead(
              'click',
              'section-eight',
              'section-eight-question-twelve'
            )
          }
        />
        <CommonQuestion
          question="13. Como posso contratar os serviços de vocês?"
          answer={
            <p>
              É muito simples! Entre em contato pelo nosso formulário de
              contato, e-mail ou telefone. Nossa equipe estará pronta para
              entender sua necessidade e encaminhar um orçamento detalhado.
            </p>
          }
          onClick={async () =>
            HandleLead(
              'click',
              'section-eight',
              'section-eight-question-thirteen'
            )
          }
          onMouseOver={async () =>
            HandleLead(
              'click',
              'section-eight',
              'section-eight-question-thirteen'
            )
          }
        />
      </QuestionsContainer>
    </Container>
  )
}
