import { useEffect } from 'react'
import { LeadModel } from '../../../../../types/leadModel'
import { Container, Content, Text, Title } from './SectionNine.styles'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionNine = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-five')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-nine',
        'section-nine-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-nine">
      <Content>
        <Title>Nosso compromisso é com o seu sucesso!</Title>
        <Text>
          Combinando expertise contábil e atendimento personalizado, estamos
          aqui para garantir que sua empresa prospere e se mantenha sólida em um
          mercado competitivo.
        </Text>
      </Content>
    </Container>
  )
}
