import { Client } from '../../../types/client'
import {
  ClientData,
  ClientFunction,
  ClientImage,
  ClientInfo,
  ClientName,
  Container,
  Recommendation,
  Stars,
} from './ClientBox.styles'

import { FaStar } from 'react-icons/fa'

interface Props {
  client: Client
}

export const ClientBox = ({ client }: Props) => {
  return (
    <Container>
      <ClientData>
        <ClientImage>{client.name[0]}</ClientImage>
        <ClientInfo>
          <ClientName>{client.name}</ClientName>
          <ClientFunction>{client.function}</ClientFunction>
        </ClientInfo>
      </ClientData>
      <Recommendation>{client.recommendation}</Recommendation>
      <Stars>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </Stars>
    </Container>
  )
}
