import { Button } from './MainButton.styles'

interface Props {
  title: string
  onClick: () => void
  onMouseOver: () => void
}

export const MainButton = ({ title, onClick, onMouseOver }: Props) => {
  return (
    <Button onClick={onClick} onMouseOver={onMouseOver}>
      {title}
    </Button>
  )
}
