import { useEffect } from 'react'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { LeadModel } from '../../../../../types/leadModel'
import { Container, Content, Title } from './SectionFive.styles'
import { ItemService } from '../../../../commons/ServiceItem'
import { MainButton } from '../../../../commons/MainButton'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionFive = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-five')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-five',
        'section-five-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-five">
      <Title>Qualidade e Estratégia certa em nossos serviços</Title>
      <Content>
        <ItemService
          title="Abertura de Empresa"
          description="Transforme sua ideia em realidade com nosso suporte especializado. Cuidamos de todo o processo burocrático, para que você possa focar no crescimento do seu negócio. Além disso, oferecemos uma consultoria gratuita para ajudá-lo a planejar os primeiros passos da sua empresa."
          onClick={() => {
            HandleLead('click', 'section-five', 'section-five-service-one')
          }}
          onMouseOver={() => {
            HandleLead('mouse-over', 'section-five', 'section-five-service-one')
          }}
        />
        <ItemService
          title="Migração da MEI para o Simples Nacional"
          description="Evolua sua empresa para o Simples Nacional, ampliando suas possibilidades de crescimento. Nossa equipe cuida de todo o processo, permitindo que você expanda suas operações e contrate mais funcionários, sem complicações contábeis."
          onClick={() => {
            HandleLead('click', 'section-five', 'section-five-service-two')
          }}
          onMouseOver={() => {
            HandleLead('mouse-over', 'section-five', 'section-five-service-two')
          }}
        />
        <ItemService
          title="Levantamento de Débito do CNPJ"
          description="Descubra e regularize suas pendências fiscais com facilidade. Realizamos um levantamento completo dos débitos do seu CNPJ, oferecendo soluções para manter sua empresa sempre em conformidade e evitar surpresas desagradáveis."
          onClick={() => {
            HandleLead('click', 'section-five', 'section-five-service-three')
          }}
          onMouseOver={() => {
            HandleLead(
              'mouse-over',
              'section-five',
              'section-five-service-three'
            )
          }}
        />
        <ItemService
          title="Encerramento de Empresa"
          description="Encerrar uma empresa pode ser complexo, mas nós tornamos o processo simples e tranquilo. Cuidamos de todos os detalhes burocráticos, para que você possa seguir em frente com segurança e sem preocupações."
          onClick={() => {
            HandleLead('click', 'section-five', 'section-five-service-four')
          }}
          onMouseOver={() => {
            HandleLead(
              'mouse-over',
              'section-five',
              'section-five-service-four'
            )
          }}
        />
        <ItemService
          title="Parcelamento de Dívidas com a Receita"
          description="Negocie com a Receita Federal as melhores condições de parcelamento. Nós garantimos que você possa regularizar suas dívidas de forma planejada e sustentável, evitando bloqueios e penalidades."
          onClick={() => {
            HandleLead('click', 'section-five', 'section-five-service-five')
          }}
          onMouseOver={() => {
            HandleLead(
              'mouse-over',
              'section-five',
              'section-five-service-five'
            )
          }}
        />
        <ItemService
          title="Declaração de Imposto de Renda"
          description="Fique em dia com o Leão sem complicações. Deixe sua declaração de Imposto de Renda em nossas mãos, evitando erros e garantindo que você não caia na malha fina."
          onClick={() => {
            HandleLead('click', 'section-five', 'section-five-service-six')
          }}
          onMouseOver={() => {
            HandleLead('mouse-over', 'section-five', 'section-five-service-six')
          }}
        />
        <ItemService
          title="Contabilidade para Empresas no Simples Nacional"
          description="Oferecemos uma gestão contábil completa para sua empresa no Simples Nacional, com serviços que incluem declaração mensal, declaração anual, emissão de guias de impostos, emissão de nota fiscal, gestão de pró-labore, e mais."
          action={
            <MainButton
              title="Solicitar uma Consultoria Gratuita"
              onClick={() => {
                HandleLead('click', 'section-five', 'section-five-main-button')
              }}
              onMouseOver={() => {
                HandleLead(
                  'mouse-over',
                  'section-five',
                  'section-five-main-button'
                )
              }}
            />
          }
          onClick={() => {
            HandleLead('click', 'section-five', 'section-five-service-seven')
          }}
          onMouseOver={() => {
            HandleLead(
              'mouse-over',
              'section-five',
              'section-five-service-seven'
            )
          }}
        />
      </Content>
    </Container>
  )
}
