import { useEffect } from 'react'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { LeadModel } from '../../../../../types/leadModel'
import { Advantages, Container, Title } from './SectionThree.styles'
import { AdvantageItem } from '../../../../commons/AdvantageItem'
import { MainButton } from '../../../../commons/MainButton'
import {
  IoCloud,
  IoConstructSharp,
  IoFlashSharp,
  IoShieldCheckmark,
} from 'react-icons/io5'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionThree = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-three')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-three',
        'section-three-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-three">
      <Title>Por que escolher nossos serviços?</Title>
      <Advantages>
        <AdvantageItem
          icon={<IoFlashSharp />}
          title="Eficiência e Tranquilidade"
          content="Cuidamos de toda a burocracia para que você possa focar no crescimento da sua empresa."
        />
        <AdvantageItem
          icon={<IoShieldCheckmark />}
          title="Expertise e Segurança"
          content="Nossos especialistas asseguram que sua empresa esteja sempre em conformidade com as leis e regulamentações."
          colorful
        />
        <AdvantageItem
          icon={<IoCloud />}
          title="Proatividade e Confiança"
          content="Monitoramos constantemente suas obrigações fiscais para evitar surpresas desagradáveis."
        />
        <AdvantageItem
          icon={<IoConstructSharp />}
          title="Suporte Completo"
          content="De serviços contábeis essenciais a consultorias estratégicas, oferecemos um pacote completo para o sucesso do seu negócio."
          colorful
        />
      </Advantages>
      <MainButton
        title="Solicitar uma Consultoria Gratuita"
        onClick={async () => {}}
        onMouseOver={async () => {
          await HandleLead(
            'mouse-over',
            'section-three',
            'section-three-main-button'
          )
        }}
      />
    </Container>
  )
}
