import { useEffect } from 'react'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { LeadModel } from '../../../../../types/leadModel'
import { TeamCard } from '../../../../commons/TeamCard'
import { Container, Content, Team, Text, Title } from './SectionSix.styles'
import TeamData from '@/../../src/utils/team.json'
import { TeamModel } from '../../../../../types/team'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionSix = ({ SendLead, ip, userAgent }: Props) => {
  const teamMembers: TeamModel[] = TeamData.team

  const timeSpent = useTimeSpentInSection('section-six')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-six',
        'section-six-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-six">
      <Content>
        <Title>Time de profissionais qualificados no mercado</Title>
        <Text>
          Somos mais que um escritório contábil – somos parceiros na jornada do
          seu negócio.
        </Text>
      </Content>
      <Team>
        {teamMembers.map((teamMember, index) => {
          return (
            <TeamCard
              image=""
              name={teamMember.name}
              functionTitle={teamMember.function}
              onClick={async () =>
                HandleLead(
                  'click',
                  'section-six',
                  `section-six-team-card-${teamMember.name}`
                )
              }
              onMouseOver={async () =>
                HandleLead(
                  'mouse-over',
                  'section-six',
                  `section-six-team-card-${teamMember.name}`
                )
              }
              key={index}
            />
          )
        })}
      </Team>
    </Container>
  )
}
