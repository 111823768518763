import {
  AvatarIcon,
  Container,
  Function,
  Name,
  ProfileImage,
} from './TeamCard.styles'

import { IoPerson } from 'react-icons/io5'

interface Props {
  image?: string
  name: string
  functionTitle: string
  onClick: () => Promise<void>
  onMouseOver: () => Promise<void>
}

export const TeamCard = ({
  image,
  name,
  functionTitle,
  onClick,
  onMouseOver,
}: Props) => {
  return (
    <Container onClick={onClick} onMouseOver={onMouseOver}>
      {image ? (
        <ProfileImage src={image} />
      ) : (
        <AvatarIcon>
          <IoPerson />
        </AvatarIcon>
      )}
      <Name>{name}</Name>
      <Function>{functionTitle}</Function>
    </Container>
  )
}
