import styled from 'styled-components'

export const Container = styled.div<{ colorful?: boolean }>`
  width: 17.25rem;
  height: 18.75rem;
  border-radius: 1rem;
  padding: 1.25rem 1.5rem 0;
  background: ${(props) =>
    props.colorful ? props.theme.colors.secondary : props.theme.colors.white};
`

export const Icon = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.5rem;
  background: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  & > :first-child {
    font-size: 2rem;
    color: ${(props) => props.theme.colors.white};
  }
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1.125rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.black};
  margin: 3.5rem 0 0.75rem;
`

export const Content = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  max-width: 13.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.tertiary};
  line-height: 1.5rem;
`
